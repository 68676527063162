:root {
  --page-padding: 30px;
  --page-background: #f6f2f0;
  --z-lowest: 0;
  --z-middle: 5;
  --z-highest: 10;
  --f-s: .718rem;
  --f-m: 1rem;
  --f-l: 1.392rem;
  --f-xl: 1.938rem;
  --f-xxl: 2.697rem;
  --f-xxxl: 3.755rem;
  --f-xxxxl: 5.226rem;
  --f-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --s-m: 24px;
  --s-l: 48px;
  --s-xl: 72px;
  --s-xxl: 144px;
  --s-xxxl: 216px;
  --c-text: #292929;
  --c-highlight: #1dca5a;
  --c-highlight-darken: #18a549;
  --c-border: #0000001c;
}

::selection {
  background: #00945940;
}

html {
  font-size: 16px;
}

@media (width >= 600px) {
  html {
    font-size: 17px;
  }
}

@media (width >= 960px) {
  html {
    font-size: 18px;
  }
}

body {
  background-color: var(--page-background);
  font-family: var(--f-family);
  min-height: 100vh;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  flex-direction: column;
  margin: 0;
  line-height: 1.66666;
  display: flex;
}

main {
  flex: 1;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  letter-spacing: -.003em;
  word-break: break-word;
  color: var(--c-text);
}

ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 26px;
}

@media (width >= 600px) {
  ol {
    padding-left: 40px;
  }
}

img {
  filter: grayscale();
  max-width: 100%;
  height: auto;
}

img:hover {
  filter: grayscale(0);
}

h1, h2, p {
  margin-top: 0;
  margin-bottom: 0;
}

li {
  list-style-type: none;
}

.u-overflow-y-hidden {
  position: fixed;
  overflow-y: hidden;
}

.grid1 {
  grid-gap: 20px;
  margin-top: var(--s-l);
  display: grid;
}

@media (width >= 600px) {
  .grid1 {
    grid-gap: 40px;
    grid-template-columns: 2.555fr 1fr;
  }

  .grid1 > img {
    display: block;
  }
}

.loading footer {
  display: none;
}

.leviatingH {
  color: var(--c-highlight);
  animation: 2s cubic-bezier(.17, .67, .83, .67) infinite floatH;
  display: inline-block;
}

@keyframes floatH {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-3px);
  }

  100% {
    transform: translateX(0);
  }
}

.leviatingV {
  color: var(--c-highlight);
  margin-left: 32px;
  animation: 2s cubic-bezier(.17, .67, .83, .67) infinite floatV;
  display: inline-block;
}

@media (width >= 600px) {
  .leviatingV {
    margin-left: 0;
  }
}

@keyframes floatV {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateY(-3px);
  }

  100% {
    transform: translateX(0);
  }
}

.raining {
  visibility: hidden;
}

.raining > span {
  transition: all 3s linear;
}
/*# sourceMappingURL=index.118f6b7c.css.map */

:root {
    --page-padding: 30px;
    --page-background: #f6f2f0;

    --z-lowest: 0;
    --z-middle: 5;
    --z-highest: 10;

    --f-s: 0.718rem;
    --f-m: 1rem;
    --f-l: 1.392rem;
    --f-xl: 1.938rem;
    --f-xxl: 2.697rem;
    --f-xxxl: 3.755rem;
    --f-xxxxl: 5.226rem;

    --f-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
        Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    --s-m: 24px;
    --s-l: 48px;
    --s-xl: 72px;
    --s-xxl: 144px;
    --s-xxxl: 216px;

    --c-text: rgba(41, 41, 41, 1);
    --c-highlight: #1dca5a;
    --c-highlight-darken: #18a549;

    --c-border: rgba(0, 0, 0, 0.11);
}

::selection {
    background: #00945940;
}

html {
    font-size: 16px;
}

@media (min-width: 600px) {
    html {
        font-size: 17px;
    }
}

@media (min-width: 960px) {
    html {
        font-size: 18px;
    }
}

body {
    margin: 0;
    background-color: var(--page-background);
    font-family: var(--f-family);
    display: flex;
    min-height: 100vh;
    line-height: 1.66666;
    flex-direction: column;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

main {
    flex: 1;
}

a {
    color: inherit;
    text-decoration: none;
}

p {
    letter-spacing: -0.003em;
    word-break: break-word;
    color: var(--c-text);
}

ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

ol {
    padding-left: 26px;
    margin-top: 0;
    margin-bottom: 0;
}

@media (min-width: 600px) {
    ol {
        padding-left: 40px;
    }
}

img {
    max-width: 100%;
    height: auto;
    filter: grayscale(1);
}

img:hover {
    filter: grayscale(0);
}

h1,
h2,
p {
    margin-top: 0;
    margin-bottom: 0;
}

li {
    list-style-type: none;
}

.u-overflow-y-hidden {
    overflow-y: hidden;
    position: fixed;
}

/* on thoughts page */
.grid1 {
    display: grid;
    grid-gap: 20px;
    margin-top: var(--s-l);
}

/* TODO: create nice img galerie :) with mask pictures ad wood? */
@media (min-width: 600px) {
    .grid1 {
        grid-template-columns: 2.555fr 1fr;
        grid-gap: 40px;
    }

    .grid1>img {
        display: block;
    }
}

.loading footer {
    display: none;
}

/* floating arrow on home */
.leviatingH {
    animation: floatH 2s cubic-bezier(0.17, 0.67, 0.83, 0.67) infinite;
    display: inline-block;
    color: var(--c-highlight);
}

@keyframes floatH {
    0% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(-3px);
    }

    100% {
        transform: translateX(0px);
    }
}

.leviatingV {
    animation: floatV 2s cubic-bezier(0.17, 0.67, 0.83, 0.67) infinite;
    display: inline-block;
    color: var(--c-highlight);
    margin-left: 32px;
}

@media (min-width: 600px) {
    .leviatingV {
        margin-left: 0;
    }
}

@keyframes floatV {
    0% {
        transform: translateX(0px);
    }

    50% {
        transform: translateY(-3px);
    }

    100% {
        transform: translateX(0px);
    }
}

.raining {
    visibility: hidden;
}

.raining>span {
    transition: all 3s linear;
}